%extend-menu {
    display: flex;
    flex-direction: row;
    height: 48px;
    margin-bottom: 30px;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 87px;
}

.menu {
    @extend %extend-menu;

    &__buttonContainer {
        display: flex;
        margin-left: auto;
    }
}

.dropdown {
    @extend %extend-menu;
}

.title {
    margin-top: 60px;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    color: #4b5157;
}
