.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 13px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
    margin-bottom: 48px;
}

.tab_wrapper {
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.tab {
    display: inline-block;
    max-width: 270px;
    width: 100%;
    padding: 10px;
    color: #656b70;
    text-decoration: none;
    border-bottom: 2px solid #d9dadb;
    &:hover {
        color: #656b70;
        text-decoration: none;
    }
}

.emptytab {
    height: 46px;
    width: 100%;
    display: inline-block;
    padding: 10px;
    color: #656b70;
    text-decoration: none;
    border-bottom: 2px solid #d9dadb;
}

.tab_active,
.tab_active:hover {
    text-decoration: none;
    color: #4592ff;
    border-bottom: 2px solid #4592ff;
}