.title {
    margin-top: 60px;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
}
.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 13px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}