@font-face {
    font-family: Lato-regular;
    src: url(../../../../../fonts/Lato-Regular.ttf);
}

.resource_program {
    margin-top: 59px;
}

.resource_program__back_link {
    text-decoration: none;
}

.resource_program__header {
    margin-top: 13px;
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
}

.table__pagination {
    margin-top: 32px;
    margin-bottom: 68px;
}

.table__header {
    display: grid;
    grid-template-columns: 2fr 10fr;
    padding: 14px;
    border-bottom: 1px solid #EBEEF2;
}

.resource_program__modal_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-top: 32px;
}

.modal_style_fix {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.button__create_material {
    padding: 12px 64px;
    border-radius: 30px;
}

.modal_button {
    border-radius: 30px;
    padding: 12px 18px;
}

.modalTitle {
    font-family: Lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
}