.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(48, 54, 61, 0.8);
    backdrop-filter: blur(16px);
    z-index: 2;
    text-align: center;
    white-space: nowrap;
}

.modalOverlay::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
}

.modalWindow {
    display: inline-block;
    vertical-align: middle;
    width: 612px;
    min-height: 316px;
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 24px;
    margin: 177px auto;
    z-index: 2;
    text-align: start;
}

.popUp {
    width: 612px;
    background-color: white;
    padding: 40px 24px;
}

.popUpTitle {
    font-family: Lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 36px;
}

.popUpTxt {
    font-family: Lato-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 32px;
}

.deleteLesson {
    padding: 12px 18px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    width: 270px;
    margin: 0 22px 0 0;
}

.canselChange {
    box-sizing: border-box;
    background: #4592ff;
    border: 1px solid #4592ff;
    border-radius: 100px;
    padding: 12px 18px;
    width: 270px;
}

.loading {
    width: 1rem;
    height: 1rem;
}

.form {
    &_first_lable {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #656B70;
    }
    &_second_lable {
        margin-top: 12px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #939699;
    }
    &_price {
        &_wrapper {
            position: relative;
        }
        &_img {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 18px;
            background: white;
        }
        padding: 0 30px 0 14px;
    }
}