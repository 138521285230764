.button_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-bottom: 13px;
    &__text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}
