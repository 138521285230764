.radio-button {
  --size: 20px;
  position: relative;
  width: var(--size);
  height: var(--size);
  margin: 0;
  padding: 0;

  input[type="radio"] {
    visibility: hidden;
    position: absolute;
    width: var(--size);
    height: var(--size);
    margin: 0;
    padding: 0;

    &::after {
      content: "";
      visibility: visible;
      position: absolute;
      width: var(--size);
      height: var(--size);
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #939699;
      border-radius: 50%;
    }

    &:checked::after {
      border: 6px solid #4592ff;
    }
  }

  &__wrapper {
    height: 40px;
    display: flex;
    align-items: center;
  }
}
