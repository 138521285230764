.training_program {
  margin-top: 59px;
}
.training_program__back_link {
  text-decoration: none;
}

.training_program__header__margin {
  margin-top: 13px;
}

.training_program_blocks {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 39px;
  margin-top: 48px;
}
.training_program_blocks__link {
  text-decoration: none;
}
.training_program_blocks__link:hover {
  box-shadow: 5px 10px 20px rgb(158, 158, 158);
  border-radius: 14px;
  text-decoration: none;
}
.training_program_blocks__block {
  background-color: white;
  white-space: pre-wrap;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 30px 60px rgba(1, 1, 1, 0.03);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 128px;
}
.training_program_blocks {
  &__title {
    margin: 0;
  }
  &__title2 {
    margin: 0 0 0 -5px;
  }
}
.blocks__titles {
  display: flex;
  flex-direction: column;
  align-items: start;
}