$red-color: #dc3545;
$border-radius: 14px;

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    text-align: center;
    overflow-y: auto;

    &__content {
        display: inline-block;
        vertical-align: middle;
        text-align: start;
        border-radius: $border-radius;
        background: #ffffff;
        z-index: 2;
        position: relative;
        overflow: hidden;

        padding: 35px 30px 30px;
        width: 570px;
        min-height: 263px;
        overflow: unset;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__title {
                margin: 0;
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
            }

            &__close {
                width: 40px;
                height: 40px;
                background: #f4f8fc;
                border-radius: $border-radius;
                border: 1px solid #f4f8fc;
                margin-right: -16px;
                margin-top: -16px;
                cursor: pointer;
                display: flex;
                padding: 0;
                img {
                    margin: auto;
                }
            }
        }

        &__body {
            margin-top: 16px;
            &__create {
                background: #4592ff;
                border-radius: $border-radius;
                align-items: center;
                padding: 16px 20px;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #ffffff;
                border: unset;
                width: 260px;
            }

            &__icon {
                width: 20px;
                height: 20px;
                margin-right: 6px;
                svg {
                    fill: #7b909f;
                }
            }

            &__date {
                &:hover {
                    border-color: #4e88fb;
                }
                &__invalid {
                    border: 1px solid $red-color !important;
                    &:hover {
                        border-color: $red-color;
                    }
                }
                &:disabled {
                    border: 1px solid #f4f4f4;
                    background-color: #f4f4f4;
                    &:hover {
                        border-color: #f4f4f4;
                    }
                }
                &::-webkit-calendar-picker-indicator {
                    cursor: pointer;
                }
            }

            &__error {
                border: 1px solid;
                border-radius: $border-radius;
                border-color: $red-color;
            }

            &__message {
                margin-top: 5px;
                font-size: 14px;
                color: $red-color;
            }
        }
    }
}

.modal::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: "";
}
