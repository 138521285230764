.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(48, 54, 61, 0.8);
    backdrop-filter: blur(16px);
    z-index: 2;
    text-align: center;
    white-space: nowrap;
}

.modalOverlay::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
}

.modalWindow {
    display: inline-block;
    vertical-align: middle;
    width: 628px;
    min-height: 432px;
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 24px;
    z-index: 2;
    text-align: start;
}

.popUp {
    width: 612px;
    background-color: white;
    padding: 40px 24px;
}

.popUpTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 36px;
}

.popUpTxt {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 32px;
}

.deleteLesson {
    padding: 12px 18px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    width: 270px;
    margin: 0 22px 0 0;
}

.canselChange {
    box-sizing: border-box;
    background: #4592ff;
    border: 1px solid #4592ff;
    border-radius: 100px;
    padding: 12px 18px;
    width: 270px;
}

.loading {
    width: 1rem;
    height: 1rem;
}

.first_lable {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #656B70;
    margin-bottom: 8px;
}

.second_lable {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #939699;
    margin-bottom: 12px;
}

.file {
    display: flex;
    flex-direction: column;
    &_input {
        cursor: pointer;
        background: #FFFFFF;
        border: 1px dashed #4592FF;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 14px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #4592FF;
        display: flex;
        justify-content: space-between;
    }
}

.inputImgWrapper {
    display: flex;
    align-items: center;
    width: 237px;
    justify-content: space-between;
}

.inputImgWrapperTxt {
    margin: 0;
}