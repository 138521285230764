.lesson {
    padding: 6.5px;
    width: 100%;
    height: 136px;
    border: 1.5px dashed transparent;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    color: #4b5157;
    background-color: #f9da71;
    position: absolute;
    z-index: 10;

    &__content {
        height: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &__top {
            display: flex;
            flex-direction: row;

            &__bell {
                width: 13px;
                height: 14px;
                margin-right: 4px;
              }

            &__title {
                font-style: normal;
                max-height: 28px;
                font-weight: 700;
                font-size: 13px;
                line-height: 14px;
                margin-bottom: 4px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 82%;
                cursor: "unset"
            }

            &__icon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0;
                margin-left: auto;
            }
        }

        &__subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 100%;
            color: #a5a8ab;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        &__student {
            height: 24px;
            overflow: hidden;
            position: absolute;
            top: 1px;
            right: 0;

            &__name {
                font-size: 6px;
                margin-top: 4px;
                font-weight: 400;
                font-size: 10px;
                line-height: 10px;
            }
        }

        &__description {
            margin-top: 15px;
            padding: 0;

            &__text {
                font-weight: 400;
                font-size: 10px;
                line-height: 10px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    &__time {
        margin-bottom: 0;
        margin-top: auto;
        font-weight: 400;
        font-size: 10px;
        line-height: 140%;
        color: #4b5157;
    }
}

.popover {
    --bs-popover-border-radius: 14px;
    --bs-popover-inner-border-radius: 14px;

    &__inner {
        background: #ffffff;
        border-radius: 14px;
    }

    &__arrow {
        transform: translate(0px, 17px) !important;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        border-radius: 14px;
        overflow: hidden;

        &__item {
            border: none;
            background: #ffffff;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 34px;
            padding: 10px;
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: #4b5157;
            margin: 0;

            &:hover {
                background: #f4f8fc;
            }

            svg {
                margin-right: 8px;
            }
        }
    }
}
