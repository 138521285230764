.title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
    &_header{
        margin-bottom: 48px;
        margin-top: 48px;
    }
}

.header {
    &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }
    &_title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #262D33;
        margin: 0;
    }
    &_add_button {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #4592FF;
        &:hover {
            color: #4592FF;
        }
        svg {
            margin-right: 10px;
        }
    }
}

.select {
    max-width: 170px;
    width: 100%;
}

.table {
    &_width {
        width: 100%;
    }
    &_header {
        border-bottom: 1px solid #ebeef2;
        height: 64px;
    }
    &_body {
        border-bottom: 1px solid #ebeef2;
        height: 64px;
    }
    &_number {
        padding: 23px 12px;
        width: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;
        &_header {
            padding: 23px 12px;
            width: 100px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }
    &_name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #262D33;
        padding: 23px 12px;
        svg {
            margin-left: 12px;
        }
        &_header {
            padding: 23px 12px;
            width: 600px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }
    &_price {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #262D33;
        &_header {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
            width: 200px;
        }
    }
}

.searhWrapper {
    position: relative;
    margin-bottom: 22px;
    .searchImg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0, -50%);
    }
}

.findUsers {
    max-width: 470px;
    width: 100%;
    border: 1px solid #939699;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 16px 10px 40px;
    &:hover {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
    &:focus-within {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
}

.checkbox {
    margin: 0;
}

.lable {
    &_active {
        margin-left: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #4592FF;
    }
    &_unactive {
        margin-left: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #939699;
    }
}

.lessons_Null {
    margin-top: 24px;
    color: #939699;
    font-family: Lato-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: start;
}

.lessonPagination {
    display: flex;
    justify-content: flex-start;
    margin: 18px 0 38px 0;
}