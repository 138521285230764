.fileLable {
  width: 224px;
  height: 36px;
  border: 2px solid #4592FF;
  box-sizing: border-box;
  border-radius: 100px;
  color: #4592FF;
  margin: 12px 0 12px 0;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
}

.fileInput {
  opacity: 0;
  display: none;
}
.fileImg {
  margin: 0 10px 0 16px;
}
.loaded_info {
  display: flex;
  column-gap: 20px;
  align-items: center;
}