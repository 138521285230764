.table__row {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 1px solid #EBEEF2;
}

.table__icon_delete {
    display: flex;
    justify-content: end;
}

.table__icon {
    margin-left: 17px;
}

.table__link {
    text-decoration: none;
    margin-right: 17px;
    color: #262D33;
    &:hover {
        color: #4E88FB;
    }
    &:focus {
        color: #307ADB;
    }
}

.table__button {
    display: flex;
    padding: 0;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    color: #262D33;
    align-items: center;
    &:hover {
        color: #4E88FB;
    }
}