.date {
    padding-left: 24px;

    &__month {
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 120%;
        color: #7b909f;
        margin-bottom: 4px;
    }

    &__week {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #5b6168;
        text-transform: uppercase;
    }
}
