.wrapper {
  margin-top: 59px;
}

.back_link {
  text-decoration: none;
}

.header {
  margin-top: 13px;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin-top: 30px;
  margin-bottom: 39px;
  padding: 0;
}

.tab_wrapper {
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.tab {
  display: inline-block;
  width: 270px;
  padding: 10px;
  color: #656b70;
  text-decoration: none;
  border-bottom: 2px solid #d9dadb;
  &:hover {
    color: #656b70;
    text-decoration: none;
  }
}

.emptytab {
  height: 46px;
  width: 100%;
  display: inline-block;
  padding: 10px;
  color: #656b70;
  text-decoration: none;
  border-bottom: 2px solid #d9dadb;
}

.tab_active,
.tab_active:hover {
  text-decoration: none;
  color: #4592ff;
  border-bottom: 2px solid #4592ff;
}

.modal {
  justify-content: center;
  column-gap: 24px;
}

.button {
  margin-top: 47px;
}

.material_block__text {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-right: 36px;
}

.material_block {
  margin-top: 39px;
}

.modal_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 32px;
}

.button__create_material {
  margin-top: 40px;
  padding: 12px 64px;
}

.findUsers {
  width: 600px;
}
