@import 'utils/colors';
@import "./styles/base/typography";
@import "./styles/components/index.scss";
body {
    margin: 0;
    font-family: 'Lato';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.title-h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: $gray;
}

.title-h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: $grey-100;
}

:root {
    --toastify-color-dark: #4b5157;
}

.Toastify__toast-body {
    display: flex;
    justify-content: center;
    text-align: center;
}

.Toastify__toast-icon {
    display: none;
}

.Toastify__close-button {
    display: none;
}

.Toastify__toast-theme-dark {
    background-color: #4b5157;
}

:focus-visible {
    outline: none;
}

.rmsc .item-renderer {
    display: flex;
    align-items: center !important;
}

input[type=date]::-webkit-calendar-picker-indicator {
    background-image: url(./assets/img/calendar.svg);
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 1;
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
    z-index: 1;
}

input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background: url(./assets/img/cancel.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
        cursor: pointer;
    }
}

a:hover {
    text-decoration: none;
}

.mb-4 {
    margin-bottom: 2.25rem!important;
}