.deleteLesson {
    padding: 12px 18px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    width: 270px;
    margin: 0 22px 0 0;
  }
  
  .canselChange {
    box-sizing: border-box;
    background: #4592ff;
    border: 1px solid #4592ff;
    border-radius: 100px;
    padding: 12px 18px;
    width: 270px;
  }
  
  .displayButton {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .titleName{
    margin-bottom: 20px;
  }

  .textFile{
    color:#14934F;
    margin-top: 10px;
  }