.wrapper {
    display: flex;
    flex-direction: column;
    margin: 87px 0 0 0;
}

.lesson_list_back_link {
    text-decoration: none;
    padding: 0;
}

.listHeader {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 13px 0 38px 0;
}

.listTopTxt {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    margin: 0;
}

.createLesson {
    padding: 12px 18px;
    width: 169px;
    background: #4592ff;
    border-radius: 100px;
    border: 1px;
}

.links {
    display: none;
}

.forTeacher {
    color: #4592ff;
    margin: 0 8px 0 0;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 6px 14px;
}

.forStudent {
    padding: 6px 14px;
    background: #4592ff;
    border-radius: 100px;
    box-sizing: border-box;
    border: 1px solid #4592ff;
    margin: 16px 0;
}

.tableBorder {
    border-bottom: 1px solid #ebeef2;
}

.tableHeader {
    padding: 0 0 14px 0;
}

.tableHeaderLessonName {
    padding: 0 0 14px 0;
    width: 225px;
    word-break: break-all;
    text-align: center;
}

.tableLessonName {
    width: 225px;
    word-break: break-all;
    cursor: pointer;
    text-align: center;
}

.tableHeaderStatus {
    padding: 0 0 14px 0;
    text-align: center;
}

.lessonBtnWrapper {
    width: 285px;
}

.tableTeacher {
    width: 120px;
}

.tableStatusActive {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
    background: #82cc86;
    border-radius: 4px;
    width: 120px;
    height: 24px;
    padding: 4px 8px;
    margin: 23px auto;
    text-align: center;
}

.tableStatusDisabled {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
    background: #c4c4c4;
    border-radius: 4px;
    width: 120px;
    height: 24px;
    padding: 4px 8px;
    margin: 23px auto;
    text-align: center;
}

.tableStatusPlanned {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
    background: #ffd150;
    border-radius: 4px;
    width: 120px;
    height: 24px;
    padding: 4px 8px;
    margin: 23px auto;
    text-align: center;
}

.tableDeleteBtn {
    margin: 0 40px 0 0;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    padding: 0;
    &:focus {
        outline: none;
    }
}

.tableEditBtn {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
}

.tableEditBtnSvg {
    width: 10px;
    height: 20px;
}

.lessonPagination {
    display: flex;
    justify-content: flex-start;
    margin: 18px 0 38px 0;
}

.lessonsPrevPage {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #30363d;
}

.lessonNextPage {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #30363d;
}

.activePopUp {
    display: block;
}

.isIndividual {
    text-align: center;
    padding: 0 0 14px 0;
}

.isIndividualTrue {
    text-align: center;
    padding: 0 0 14px 0;
    background-color: yellow;
    color: black;
}

.groupLesson {
    background-color: #4592ff;
    color: black;
    margin: auto;
}

.individualLesson {
    text-align: center;
}

.lessonsListNull {
    font-size: 38px;
    margin: 40px 0 0 0;
    text-align: center;
}

.lessonsPreloader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto 0 auto;
    width: 50%;
    height: 50%;
}

.copyModalWrapper {
    position: relative;
    top: 10px;
}

.findBlock {
    display: flex;
    justify-content: space-between;
}

.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

