@font-face {
    font-family: Lato-regular;
    src: url(../../../fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: Lato-medium;
    src: url(../../../fonts/Lato-Medium.ttf);
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(48, 54, 61, 0.8);
    backdrop-filter: blur(16px);
    z-index: 2;
    text-align: center;
    white-space: nowrap;
}

.modalOverlay::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
}

.modalWindow {
    display: inline-block;
    vertical-align: middle;
    width: 612px;
    height: 322px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px 24px;
    z-index: 2;
    text-align: start;
}

.modalWrapper {
    width: 612px;
    background-color: white;
    padding: 40px 24px
}

.title {
    font-family: Lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
}

.leavePageTxt {
    font-family: Lato-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 32px 0;
}

.btnWrapper {
    display: flex;
}

.leaveBtn {
    border-radius: 100px;
    padding: 12px 18px;
    width: 270px;
    margin: 0 10px 0 0;
}

.stayBtn {
    border-radius: 100px;
    padding: 12px 18px;
    width: 270px;
    background: #4592FF;
    border: 1px solid #4592FF;
}