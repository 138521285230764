.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 13px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
    margin: 0 0 52px 0;
    &_debounce {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #262D33;
        margin: 0 0 8px 0;
    }
}

.findUsers {
    width: 530px;
    border: 1px solid #939699;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 16px 10px 40px;
    &:hover {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
    &:focus-within {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
}

.debounce_wrapper {
    margin-bottom: 32px;
}

.searhWrapper {
    position: relative;
    .searchImg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0, -50%);
    }
}

.add {
    padding: 67px 55px 60px 60px;
    border: 2px solid #A2D2FC;
    box-sizing: border-box;
    border-radius: 12px;
    background: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #A2D2FC;
    width: 300px;
    height: 162px;
    svg {
        margin-right: 13px;
        fill: #A2D2FC;
    }
}

.items {
    width: 300px;
    min-height: 162px;
    justify-content: space-between;
    padding: 20px 12px 20px 20px;
    background: #F1F5F9;
    border-radius: 12px;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.125);
    &_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 36px;
    }
    &_header {
        display: flex;
        justify-content: space-between;
        &_image {
            width: 95px;
            height: 80px;
        }
        &_btn {
            outline: none;
            border: 0;
            background: transparent;
            width: 24px;
            height: 24px;
            &_img {
                width: 18px;
                height: 18px;
            }
        }
    }
    &_name {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #4B5157;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.preloader_wrapper {
    width: 100%;
}
.nothing_find {
    color: #939699;
    font-family: Lato-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: start;
}