@font-face {
    font-family: Lato-regular;
    src: url(../../../fonts/Lato-Regular.ttf);
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(48, 54, 61, 0.8);
    backdrop-filter: blur(16px);
    z-index: 2;
    text-align: center;
    white-space: nowrap;
}

.modalOverlay::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
}

.modalWindow {
    display: inline-block;
    vertical-align: middle;
    width: 736px;
    height: 375px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px 24px;
    margin: 177px auto;
    z-index: 2;
    text-align: start;
    box-sizing: border-box;
}

.popUpTitle {
    font-family: Lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
}

.linksTitle {
    font-family: Lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 12px 0;
    color: #000000;
}

.links {
    margin: 0 0 24px 0;
    width: 688px;
}

.modalBtn {
    padding: 12px 48px;
    background: #4592FF;
    border: 1px solid #4592FF;
    border-radius: 100px;
    align-self: flex-start;
}