.custom-switch {

   .custom-control-label {
     cursor: pointer;

     &::before {
       border-color: #BCBFC2;
       background-color: #BCBFC2;
       border-radius: 10px;
       width: 35px;
       height: 20px;
       left: -45px;
     }

     &::after {
       background-color: #ffffff;
       width: 16px;
       height: 16px;
       border-radius: 10px;
       left: -43px;
     }
   }

  .custom-control-input:checked ~ .custom-control-label {

    &::before {
      border-color: #4592FF;
      background-color: #4592FF;
    }

    &::after {
      transform: translateX(15px);
    }
  }
}

