.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 48px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.linkToLk {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    width: 230px;
    height: 48px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    color: #4592ff;
    margin: 0 0 0 20px;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

.header {
    display: flex;
    justify-content: space-between;
    &_title {
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 40px;
        color: #4B5157;
        margin: 0 0 48px 0;
    }
    &_btn_wrapper{
        display: flex;
        align-items: baseline;
    }
}

.avatar {
    width: 120px;
    height: 120px;
    border: 0.5px solid #E4EBF0;
    box-sizing: border-box;
    border-radius: 300px;
    margin-bottom: 32px;
}

.pen {
    margin-right: 12px;
}



.colomn {
    &_title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #262D33;
        margin: 0 0 32px 0;
    }
    &_content {
        &_title {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #656B70;
            margin-bottom: 8px;
        }
        &_text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #262D33;
            margin-bottom: 32px;
        }
    }
}