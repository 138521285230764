$blue-color: #4592ff;
$hover-color: #217dff;

.button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 222px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    padding: 16px 14px 16px 20px;
    border: 2px solid $blue-color;
    border-radius: 16px;
    height: 48px;
    color: white;
    background-color: $blue-color;

    svg {
        fill: white;
    }

    &:hover {
        background-color: $hover-color;
        border: 2px solid $hover-color;
        color: white;
        svg {
            fill: white;
        }
    }
}
