// @font-face {
//   font-family: Lato;
//   src: url(../../../fonts/Lato-Regular.ttf);
// }
.titleHead {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.uploadResource {
    font-family: Lato;
    color: #4592ff;
    &:hover {
        color: #4592ff;
    }
}

.searhWrapper {
    position: relative;
    margin-bottom: 20px;
    .searchImg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0, -50%);
    }
}

.findMaterial {
    width: 470px;
    border: 1px solid #939699;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 16px 10px 40px;
    &:hover {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
    &:focus-within {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
}

.listHeaderInput {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    &_title {
        margin: 0 0 8px 0;
        font-family: Lato-medium;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
    }
}

.tableBorder {
    border-bottom: 1px solid #ebeef2;
    height: 64px;
}

.tableHeader {
    padding: 0 0 14px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #656b70;
}

.tableHeaderLessonName {
    padding: 0 0 14px 0;
    width: 225px;
    word-break: break-all;
    text-align: center;
}

.tableLessonName {
    width: 225px;
    word-break: break-all;
    cursor: pointer;
    text-align: center;
}

.tableHeaderStatus {
    padding: 0 0 14px 0;
    text-align: center;
}

.lessonBtnWrapper {
    width: 285px;
}

.tableTeacher {
    width: 120px;
}

.tableDeleteBtn {
    margin: 0 45px 0 0;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    padding: 0;
    &:focus {
        outline: none;
    }
}

.tableEditBtn {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
}

.tableEditBtnSvg {
    width: 10px;
    height: 20px;
}

.tableWidth {
    width: 100%;
}

.buttonDisplay {
    display: flex;
    justify-content: flex-end;
    height: 64px;
    align-items: center;
}

.itemBlockName {
    width: 800px;
    &:hover {
        cursor: pointer;
    }
}

.itemNumber {
    width: 100px;
}

.itemName {
    margin-right: 3px;
}

.itemLink {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
    }
}