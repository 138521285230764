@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../assets/fonts/Lato/Lato-Regular.woff') format('woff'), url('../../assets/fonts/Lato/Lato-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../assets/fonts/Lato/Lato-Medium.woff') format('woff'), url('../../assets/fonts/Lato/Lato-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../assets/fonts/Lato/Lato-Semibold.woff') format('woff'), url('../../assets/fonts/Lato/Lato-Semibold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../assets/fonts/Lato/Lato-Bold.woff') format('woff'), url('../../assets/fonts/Lato/Lato-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../assets/fonts/Lato/Lato-Heavy.woff') format('woff'), url('../../assets/fonts/Lato/Lato-Heavy.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}