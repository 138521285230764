@font-face {
  font-family: Lato-regular;
  src: url(../../../fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: Lato-semibold;
  src: url(../../../fonts/Lato-Semibold.ttf);
}

.wrapper {
  margin: 60px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.btn_link {
  text-decoration: none;
  align-self: flex-start;
  padding: 0;
}

.inputFileWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.inputImg {
  display: flex;
}

.inputImgFirstImg {
  margin: 0 130px 0 0;
}

.inputImgName {
  width: 270px;
}

.submitBtn {
  width: 270px;
  padding: 12px 32px;
  background: #4592ff;
  border: 1px;
  border-radius: 100px;
  margin: 0 0 20px 0;
}

.fileImg {
  margin: 0 10px 0 16px;
}

.fileLable {
  width: 236px;
  height: 36px;
  border: 2px solid #4592ff;
  box-sizing: border-box;
  border-radius: 100px;
  color: #4592ff;
  margin: 24px 0 12px 0;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
  &:hover{
    color: #4592ff;
  }
}

.fileInput {
  opacity: 0;
  display: none;
}

.inputImgTxt {
  font-family: Lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.inputLable {
  font-family: Lato-semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.pageTitle {
  font-family: Lato-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 40px;
  margin: 13px 0 24px 0;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid black;
  width: 736px;
  height: 344px;
  background: #ffffff;
  border-radius: 20px;
  padding: 40px 24px;
  box-sizing: border-box;
}

.leavePage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid black;
  background: #ffffff;
  border-radius: 20px;
  padding: 40px 24px;
  box-sizing: border-box;
  width: 621px;
  text-align: start;
}

.selectTeacher {
  width: 270px;
}

.toilet {
  margin: 0 265px 24px 0;
  padding: 0;
}

.individualLesson {
  padding: 0;
}

.chooseFile {
  margin: 24px 0 12px 0;
}

.checkboxLable {
  margin: 0 0 0 40px;
}

.inputImgWrapper {
  display: flex;
  margin: 24px 0 12px 0;
}

.inputImgWrapperTxt {
  margin: 0;
  align-self: center;
}

.inputImgWrapperImg {
  margin: 0 10px 0 0;
}

.inputImgWrapperBtn {
  padding: 0;
  margin: 0 0 0 10px;
}

.error {
  color: red;
  margin: 10px 0 0 0;
}

.visible {
  display: block;
  color: red;
  margin: 5px 0 10px 0;
}

.unvisible {
  display: none;
}

.toogleWrapper {
  display: flex;
}

.loading {
  width: 1rem;
  height: 1rem;
}
