.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(48, 54, 61, 0.8);
    backdrop-filter: blur(16px);
    z-index: 2;
    text-align: center;
    white-space: nowrap;
}

.modalOverlay::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
}

.modalHeader {
    display: flex;
    justify-content: space-between;
}

.modalWindow {
    display: inline-block;
    vertical-align: middle;
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 24px;
    z-index: 2;
    text-align: start;
    min-width: 612px;
    max-width: 970px;
    max-height: 825px;
    padding: 32px;
    box-sizing: border-box;
}

.popUp {
    width: 612px;
    background-color: white;
    padding: 40px 24px;
}

.popUpTitle {
    font-family: Lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
}

.popUpTxt {
    font-family: Lato-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 32px;
}

.deleteLesson {
    padding: 12px 18px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    width: 270px;
    margin: 0 22px 0 0;
}

.canselChange {
    box-sizing: border-box;
    background: #4592ff;
    border: 1px solid #4592ff;
    border-radius: 100px;
    padding: 12px 18px;
    width: 270px;
}

.fileLable {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    width: 230px;
    height: 48px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    color: #4592ff;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
}

.cancelButton {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    width: 230px;
    height: 48px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    color: #4592ff;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
    &:hover{
        color: #4592ff;
    }
}

.createLesson {
    padding: 12px 18px;
    width: 230px;
    height: 48px;
    background: #4592ff;
    border-radius: 100px;
    border: 1px;
}

.addImageButton {
    margin-right: 5px;
}

.titleMessage {
    font-weight: 600;
    margin-bottom: 20px;
}

.closeButton {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    border: 2px solid white;
    margin: 0 16px 0 0;
    cursor: pointer;
    &:hover{
       border: 2px solid #4592ff; 
    }
}

.closeIcon {
    width: 10px;
    display: flex;
    align-items: center;
}

.displayButton {
    margin-top: 10px;
}