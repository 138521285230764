.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 48px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.linkToLk {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    width: 230px;
    height: 48px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    color: #4592ff;
    margin: 0 0 0 20px;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

.header {
    display: flex;
    justify-content: space-between;
    &_title {
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 40px;
        color: #4B5157;
        margin: 0 0 48px 0;
    }
    &_btn_wrapper{
        display: flex;
        align-items: baseline;
    }
}

.avatar {
    width: 120px;
    height: 120px;
    border: 0.5px solid #E4EBF0;
    box-sizing: border-box;
    border-radius: 300px;
    margin-bottom: 32px;
}

.devaider {
    margin: 48px 0;
}

.pen {
    margin-right: 12px;
}

.tab_wrapper {
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.tab {
    display: inline-block;
    width: 270px;
    padding: 10px;
    color: #656b70;
    text-decoration: none;
    border-bottom: 2px solid #d9dadb;
    &:hover {
        color: #656b70;
        text-decoration: none;
    }
}

.emptytab {
    height: 46px;
    width: 100%;
    display: inline-block;
    padding: 10px;
    color: #656b70;
    text-decoration: none;
    border-bottom: 2px solid #d9dadb;
}

.tab_active,
.tab_active:hover {
    text-decoration: none;
    color: #4592ff;
    border-bottom: 2px solid #4592ff;
}

.colomn {
    &_title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #262D33;
        margin: 0 0 32px 0;
    }
    &_content {
        &_title {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #656B70;
            margin-bottom: 8px;
        }
        &_text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #262D33;
            margin-bottom: 32px;
        }
    }
}

.education_forms {
    margin-bottom: 48px;
    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
    }
    &_title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #262D33;
    }
    &_history {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #4592FF;
        svg {
            stroke: #4592FF;
        }
        &:hover {
            color: #4592FF;
            svg {
                stroke: #4592FF;
            }
        }
    }
    &_add {
        padding: 53px 27px;
        border: 2px solid #A2D2FC;
        box-sizing: border-box;
        border-radius: 12px;
        background: transparent;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #A2D2FC;
        height: 130px;
        svg {
            margin-right: 13px;
            fill: #A2D2FC;
        }
    }
    &_wrapper {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
    }
    &_item {
        padding: 20px 12px 20px 20px;
        box-sizing: border-box;
        background: #F1F5F9;
        border-radius: 12px;
        max-width: 270px;
        width: 100%;
        height: 130px;
        cursor: pointer;
        &_name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #4B5157;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 85%;
            &_wrapper{
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                justify-content: space-between;
            }
        }
        &_name_unactive{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #939699;
            margin: 0 ;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &_duration {
            margin: 0 0 16px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: #4B5157;
        }
        &_duration_unactive{
            margin: 0 0 16px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: #939699;
        }
        &_status {
            &_active {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #1DAB5F;
                margin: 0;
            }
            &_unactive {
                margin: 0;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #939699;
            }
        }
        &_btn {
            outline: none;
            border: 0;
            background: transparent;
            &_img {
                width: 18px;
                height: 18px;
            }
        }
    }
    &_free{
        background: rgba(255, 209, 80, 0.22);
        padding: 20px 12px 20px 20px;
        box-sizing: border-box;
        border-radius: 12px;
        max-width: 270px;
        width: 100%;
        height: 130px;
        cursor: pointer;
    }
    &_active{
        background: rgba(130, 204, 134, 0.12);
        padding: 20px 12px 20px 20px;
        box-sizing: border-box;
        border-radius: 12px;
        max-width: 270px;
        width: 100%;
        height: 130px;
        cursor: pointer;
    }
}

.content_title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #262D33;
}

.menu_btn {
    list-style: none;
    padding: 12px 14px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    &:hover {
        background-color: rgba(242, 248, 255, 1);
        color: rgba(48, 122, 219, 1);
    }
}

.material{
    &_title{
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 40px;
        color: #4B5157;
    }
    &_header{
        margin-bottom: 48px;
        margin-top: 64px;
    }
}