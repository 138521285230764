.header{
    margin-top: 52px;
}
.title{
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
    margin-bottom: 48px;
    margin-top: 12px;
}