.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 13px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.findUsers {
    width: 470px;
    border: 1px solid #939699;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 16px 10px 40px;
    &:hover {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
    &:focus-within {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
}

.searhWrapper {
    position: relative;
    margin-bottom: 22px;
    .searchImg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0, -50%);
    }
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
    margin: 0 0 48px 0;
}

.price {
    &_wrapper {
        display: flex;
        margin-bottom: 32px;
    }
    &_value {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #262D33;
        margin: 0 0 0 32px;
    }
    &_text {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #262D33;
        margin: 0;
    }
}

.search_lable {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #262D33;
    margin: 0 0 8px 0;
}

.purchase_type {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #262D33;
    margin: 0 0 8px 0;
    &_input {
        max-width: 170px;
        width: 100%;
        margin-bottom: 28px;
    }
}

.table {
    width: 100%;
    &_header {
        border-bottom: 1px solid #ebeef2;
        height: 64px;
    }
    &_body {
        border-bottom: 1px solid #ebeef2;
        height: 64px;
    }
    &_number {
        padding: 23px 12px;
        width: 120px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;
        &_header {
            padding: 23px 12px;
            width: 120px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }
    &_name {
        padding: 23px 12px 23px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;
        width: 380px;
        &_header {
            width: 380px;
            padding: 23px 12px 23px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }
    &_type {
        padding: 23px 12px 23px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;
        width: 340px;
        &_header {
            width: 340px;
            padding: 23px 12px 23px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }
    &_price {
        padding: 23px 12px 23px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;
        width: 240px;
        &_header {
            width: 240px;
            padding: 23px 12px 23px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }
    &_date {
        padding: 23px 12px 23px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;
        &_header {
            padding: 23px 12px 23px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }
}