.time {
    display: flex;
    justify-content: center;
    height: 100%;
    padding-top: 20px;

    &__hour {
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 120%;
        color: #4b5157;
    }
}
