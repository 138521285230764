@font-face {
  font-family: Lato-medium;
  src: url(../../../../fonts/Lato-Medium.ttf);
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 87px 0 0 0;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 13px 0 38px 0;
}

.listTopTxt {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 40px;
  margin: 0;
}

.createLesson {
  padding: 12px 18px;
  width: 270px;
  background: #4592ff;
  border-radius: 100px;
  border: 1px;
}

.links {
  display: none;
}

.forTeacher {
  color: #4592ff;
  margin: 0 8px 0 0;
  border: 2px solid #4592ff;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 6px 14px;
}

.forStudent {
  padding: 6px 14px;
  background: #4592ff;
  border-radius: 100px;
  box-sizing: border-box;
  border: 1px solid #4592ff;
  margin: 16px 0;
}

.tableBorder {
  border-bottom: 1px solid #ebeef2;
  height: 64px;
}

.tableHeader {
  padding: 0 0 14px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #656b70;
}

.tableHeaderLessonName {
  padding: 0 0 14px 0;
  width: 225px;
  word-break: break-all;
  text-align: center;
}

.tableLessonName {
  width: 225px;
  word-break: break-all;
  cursor: pointer;
  text-align: center;
}

.tableHeaderStatus {
  padding: 0 0 14px 0;
  text-align: center;
}

.lessonBtnWrapper {
  width: 285px;
}

.tableTeacher {
  width: 120px;
}

.tableDeleteBtn {
  margin: 0 10px 0 0;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
  }
}

.tableEditBtn {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
}

.tableEditBtnSvg {
  width: 10px;
  height: 20px;
}

.lessonPagination {
  display: flex;
  justify-content: flex-start;
  margin: 18px 0 38px 0;
}

.lessonsPrevPage {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #30363d;
}

.lessonNextPage {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #30363d;
}

.activePopUp {
  display: block;
}

.lessonsPreloader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px auto 0 auto;
  width: 50%;
  height: 50%;
}

.copyModalWrapper {
  position: relative;
  top: 10px;
}

.searhWrapper {
  position: relative;
  .searchImg {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
  }
}

.findUsers {
  width: 530px;
  border: 1px solid #939699;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 16px 10px 40px;
  &:hover {
    box-shadow: #4285f4 0 0 0 1px;
    border-color: #4285f4;
    transition: all 0.5s ease-in-out;
  }
  &:focus-within {
    box-shadow: #4285f4 0 0 0 1px;
    border-color: #4285f4;
    transition: all 0.5s ease-in-out;
  }
}

.tableWidth {
  width: 100%;
}

.buttonDisplay {
  display: flex;
  justify-content: space-around;
  height: 64px;
  align-items: center;
}

.fileLable {
  font-family: Lato;
  font-weight: 800;
  font-style: normal;
  font-weight: normal;
  height: 48px;
  border: 2px solid #4592ff;
  box-sizing: border-box;
  border-radius: 100px;
  color: #4592ff;
  margin: 12px 0 12px 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 169px;
  &_text {
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    margin: 0 0 0 10px;
  }
  &:hover {
    color: #4592ff;
  }
}

.findTeacher {
  margin-top: 20px;
  padding: 12px 18px;
  width: 150px;
  background: #4592ff;
  border-radius: 100px;
  border: 1px;
}

.lessonsListNull {
  color: #939699;
  font-family: Lato-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: start;
}

.userName {
  font-family: Lato-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #262d33;
  cursor: pointer;
}

.userNameBlocked {
  font-family: Lato-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #939699;
}

.listHeaderInput {
  display: flex;
  flex-direction: column;
  &_title {
    margin: 0 0 8px 0;
    font-family: Lato-medium;
    font-style: normal;
    font-weight: normal;

    line-height: 16px;
  }
}
