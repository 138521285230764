.wrapper {
    margin-top: 32px;
}

.card {
    &_wrapper {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }
    &_item {
        max-width: 270px;
        min-height: 148px;
        width: 100%;
        // height: 100%;
        padding: 20px;
        background: #F1F5F9;
        border-radius: 12px;
        box-sizing: border-box;
        &_img {
            width: 95px;
            height: 80px;
            &_opacity {
                opacity: 0.6;
                width: 95px;
                height: 80px;
            }
        }
        &_name_opacity{
            opacity: 0.6;
        }
    }
    &_count {
        display: flex;
        align-items: center;
        &_text {
            margin: 0 8px;
            padding: 10px;
            background: #FFFFFF;
            border: 1px solid #DAE0E7;
            box-sizing: border-box;
            border-radius: 31px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #4B5157;
        }
        &_btn {
            outline: none;
            border: 0;
            background: transparent;
        }
    }
    &_clickable{
        &_count{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #979797;
        }
        &_zero{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #4592FF;
        }
    }
}

.lable {
    &_active {
        margin-left: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #4592FF;
    }
    &_unactive {
        margin-left: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #939699;
    }
}

.header_wrapper {
    display: flex;
    justify-content: space-between;
}

.edit_btn {
    border: unset;
    background: transparent;
    width: 24px;
    height: 24px;
}