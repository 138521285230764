.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(48, 54, 61, 0.8);
    backdrop-filter: blur(16px);
    z-index: 2;
    text-align: center;
    white-space: nowrap;
}

.modalOverlay::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
}

.modalHeader {
    display: flex;
    justify-content: space-between;
}

.modalWindow {
    display: inline-block;
    vertical-align: middle;
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 24px;
    z-index: 2;
    text-align: start;
    width: 628px;
    min-height: 366px;
    padding: 32px;
    box-sizing: border-box;
}

.modal {
    &_title {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 40px;
        color: #000000;
    }
    &_cancel {
        border: 2px solid #4592FF;
        box-sizing: border-box;
        border-radius: 100px;
        width: 270px;
        margin-right: 24px;
        padding: 12px 18px;
    }
    &_save {
        background: #4592FF;
        border-radius: 100px;
        border: 1px solid #4592FF;
        width: 270px;
        padding: 12px 18px;
    }
    &_button_wrapper {
        margin-top: 32px;
    }
}

.input {
    &_wrapper {
        display: flex;
        flex-direction: column;
    }
    &_block {
        display: flex;
        flex-direction: column;
    }
    &_duration {
        width: 270px;
        border-radius: 4px;
        border: 1px solid #939699;
        &_lable {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
            margin-bottom: 8px;
        }
    }
}

.button {
    &_month {
        border: 1px solid #BCBFC2;
        border-radius: 25px;
        padding: 4px 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #BCBFC2;
        background-color: white;
        margin-bottom: 28px;
        margin-top: 16px;
        &:hover {
            border: 1px solid #4592FF;
            color: #4592FF;
        }
    }
    &_week {
        border: 1px solid #BCBFC2;
        border-radius: 25px;
        padding: 4px 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #BCBFC2;
        background-color: white;
        margin-bottom: 28px;
        margin-right: 10px;
        &:hover {
            border: 1px solid #4592FF;
            color: #4592FF;
        }
    }
}