.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.header {
    margin-bottom: 48px;
}

.devider {
    margin: 36px 0;
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
    width: 50%;
    &_wrapper {
        display: flex;
        justify-content: space-between;
        &_editForm {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #4B5157;
            &:focus {
                box-shadow: none;
            }
            &_pen {
                margin-right: 8px;
            }
        }
        &_unpablish {
            padding: 10px 36px;
            border: 2px solid #4592FF;
            box-sizing: border-box;
            border-radius: 100px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #4592FF;
            margin-left: 24px;
            &:hover {
                background-color: #4592FF;
                color: white;
            }
        }
        &_publish {
            padding: 11px 36px;
            background: #4592FF;
            border-radius: 100px;
            border: 1px solid #4592FF;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #FFFFFF;
        }
    }
    &_active {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #1DAB5F;
    }
    &_unactive {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #DE373A;
    }
}

.content {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    &_title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #262D33;
        margin-bottom: 24px;
    }
    &_block {
        max-width: 410px;
        width: 100%;
        &_title {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
        &_text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #262D33;
        }
    }
}

.tab_wrapper {
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.tab {
    display: inline-block;
    max-width: 270px;
    width: 100%;
    padding: 10px;
    color: #656b70;
    text-decoration: none;
    border-bottom: 2px solid #d9dadb;
    &:hover {
        color: #656b70;
        text-decoration: none;
    }
}

.emptytab {
    height: 46px;
    width: 100%;
    display: inline-block;
    padding: 10px;
    color: #656b70;
    text-decoration: none;
    border-bottom: 2px solid #d9dadb;
}

.tab_active,
.tab_active:hover {
    text-decoration: none;
    color: #4592ff;
    border-bottom: 2px solid #4592ff;
}