.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 13px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.breadcrumbs_wrapper{
    margin-top: 58px;
    margin-bottom: 13px;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    &_title {
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 40px;
        color: #4B5157;
        margin: 0;
    }
    &_button {
        padding: 12px 36px;
        border-radius: 100px;
        background: #4592FF;
        border: 1px solid #4592FF;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        &_active{
            padding: 12px 36px;
        border-radius: 100px;
        background: white;
        border: 1px solid #4592FF;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #4592FF;
        }
    }
}

.status {
    padding-left: 20px;
}

.contact {
    display: flex;
    flex-direction: column;
    &_title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #262D33;
        margin: 0 0 32px 0;
    }
    &_content {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        &_item {
            max-width: 370px;
            width: 100%;
            &_title {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #656B70;
            }
            &_data {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #262D33;
            }
        }
    }
}

.form {
    &_title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #262D33;
        margin-bottom: 24px;
    }
    &_add {
        max-width: 470px;
        width: 100%;
        margin-right: 30px;
        height: 48px;
    }
    &_wrapper {
        display: flex;
    }
    &_btn {
        background: #4592FF;
        border-radius: 100px;
        border: 1px solid #4592FF;
        padding: 12px 36px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        box-sizing: border-box;
    }
}

.content_title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #262D33;
}

.tab_wrapper {
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.tab {
    display: inline-block;
    width: 270px;
    padding: 10px;
    color: #656b70;
    text-decoration: none;
    border-bottom: 2px solid #d9dadb;
    &:hover {
        color: #656b70;
        text-decoration: none;
    }
}

.emptytab {
    height: 46px;
    width: 100%;
    display: inline-block;
    padding: 10px;
    color: #656b70;
    text-decoration: none;
    border-bottom: 2px solid #d9dadb;
}

.tab_active,
.tab_active:hover {
    text-decoration: none;
    color: #4592ff;
    border-bottom: 2px solid #4592ff;
}

.empty_details {
    margin-top: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #656B70;
}