.loginLogo {
  width: 77px;
  height: 48px;
  margin: 30px 0 0 0;
  padding: 0;
}

.inputForm {
  margin-top: 244px;
}

.authTxt {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
  margin: 0 0 24px 0;
}

.email {
  margin: 0 0 12px;
  border: 1px inherit;
}

.wrongPassword {
  border: 1px solid red;
  color: red;
}

.inputLable {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #262d33;
  margin-bottom: 4px;
}

.submitBtn {
  margin: 24px 0 0 0;
  width: 170px;
  padding: 12px 32px;
  background: #4592ff;
  border: 1px;
  border-radius: 100px;
}

.visible {
  display: block;
  color: red;
  margin: 5px 0 10px 0;
}

.visible:nth-child(2) {
  margin: 10px 0 0 0;
}

.unvisible {
  display: none;
}

.visibleServerErrors {
  display: block;
  color: red;
  margin: 10px 0 0 0;
}

.unvisibleServerErrors {
  display: none;
}

.loading {
  width: 1rem;
  height: 1rem;
}

.changeEye {
  position: absolute !important;
  top: 1px;
  right: 0;
  height: 36px;
  transform: translate(-7%, 0%);
  display: flex;
  align-items: center;
  &:focus {
    box-shadow: unset;
    outline: 0;
  }
}
