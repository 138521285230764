.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 13px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
    margin-bottom: 48px;
}

.formsBlock {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    &_oneBlock {
        width: 270px;
        height: 134px;
        padding: 20px 12px 20px 20px;
        box-sizing: border-box;
        text-align: start;
        border-radius: 12px;
        &_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        &_title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #4B5157;
            margin: 0 0 4px 0;
            // display: flex;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            &_wrapper{
                justify-content: space-between;
                display: flex;
            }
        }
        &_active {
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: #1DAB5F;
            margin: 0 0 8px 0;
        }
        &_unactive {
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: #DE373A;
            margin: 0 0 8px 0;
        }
        &_period {
            color: #4B5157;
            margin: 0 0 16px 0;
        }
        &_price {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #4B5157;
            text-decoration: none;
            margin: 0;
        }
    }
}