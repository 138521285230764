$rowBackgroundColor: #ffffff;
$rowOpenedBackgroundColor: #F8FBFF;
$rowHoverBackgroundColor: #e8f1fc;


.tableContainer {
    width: 100%;
    overflow: auto;
}

.table {
    width: 100%;
}

.tableRow {
    background-color: $rowBackgroundColor;

    .tableColumn {
        padding: 0;
        white-space: nowrap;

        .marginLinesContainer {
            display: flex;

            .nameContainer {
                padding: 26px 12px;
                position: relative;
                display: flex;
                flex: 1;
                background-color: $rowBackgroundColor;
                align-items: center;

                .nameIconContainer {
                    margin-right: 16px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        &:last-of-type {
            width: 200px;
            min-width: 200px;
            position: sticky;
            right: 0;
            overflow: hidden;
            background-color: $rowBackgroundColor;


            .interactiveContainer {
                padding: 26px 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                

                .interactiveIconContainer {
                    display: flex;
                    align-items: center;
                    position: relative;

                    .interactiveIcon {
                        transition: .3s;

                        &Closed {
                            transform: rotateX(180deg);
                        }
                    }

                    &::before {
                        transition: .3s;
                        content: '';
                        position: absolute;
                        right: 0;
                        width: 0;
                        height: 0;
                        background-color: #6c7175;
                        border-radius: 3px;
                        top: 50%;
                        opacity: 0;
                        transform: translateY(0);

                    }

                    &HasSelected::before {
                        right: 26px;
                        width: 12px;
                        top: 0px;
                        height: 12px;
                        border-radius: 100%;
                        opacity: 1;
                        transform: translateY(-3px);
                        background-color: #4592FF;
                    }
                }
                &Radio{
                    justify-content: end !important;
                    padding-right: 20px;
                    height: 93px;
                }
                &Purchases{
                    justify-content: end !important;
                    padding-right: 20px;
                    height: 77px;
                }
            }
        }
    }

    &Folder {
        &Pointer{
            cursor: pointer;
        }

        &Opened .tableColumn {
                .marginLinesContainer .nameContainer {
                    background-color: $rowOpenedBackgroundColor;
                }
                &:last-of-type {
                    background-color: $rowOpenedBackgroundColor;
                }
            }
    }
}


.marginLine {
    width: 58px;
    margin-left: 18px;
    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 2px;
        bottom: 0;
        border-left: 1px dashed #D9DADB;
        left: 0;
    }

    &Start::before {
        top: 10px;
    }

    &End::before {
        bottom: 10px;
    }
}

.borderTop {
    border-top: 1px solid #EBEEF2;
}

.borderBottom {
    border-bottom: 1px solid #EBEEF2;

}

.tableRowPurchases{
    cursor: pointer;
}