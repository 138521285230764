$red: #DC3169;
$pink: #FA96C8;
$yellow: #FFD150;
$green: #82CC86;
$blue: #4E88FB;
$turquoise: #81E3CF;
$lightBlue: #97EDFD;
$purple: #8250CD;
$gray: #4B5157;
$lightGray: #C4C4C4;
$beige: #FAEFE4;
$wheat: #FDF8EF;
$white: #ffffff;
$grey-100: #262D33;
$grey-30: #BCBFC2;