$white-color: #f4f8fc;

.table {
    border-collapse: separate;
    border-spacing: 4px;
    table-layout: fixed;
    width: 100%;

    &__clock {
        display: flex;
        justify-content: center;
    }

    td {
        width: 44px;
        border: 1px solid hsl(204, 29%, 93%);
        border-radius: 4px;
    }

    td + td {
        width: auto;
    }

    thead tr td {
        height: 45px;
        background-color: $white-color;
    }

    tbody tr td {
        height: 204px;
        border: 1.5px solid $white-color;
    }

    tbody tr td:first-child {
        background-color: $white-color;
    }
}
