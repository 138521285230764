$white: #fff;
$blue: #4E88FB;
$green: #82CC86;
$gray-85: #4B5157;
$gray-100: #262D33;
$yellow: #FFD150;
$white-30: rgba(255, 255, 255, 0.3);
$white-70: rgba(255, 255, 255, 0.7);

.chat_bubble__menu_container {
  position: relative;
  display: inline-block;
}
.chat_bubble__menu_list {
  background: white;
  box-shadow: 10px 10px 6px #c7c7c7;
  border-radius: 12px;
  border: 1px solid #c7c7c7;
  color: $gray-85;
  position: absolute;
  top: -95px;
  right: 0;
  width: 190px;
}
.chat_bubble__menu_list_item {
  list-style: none;
  padding: 12px 14px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  &:hover {
    text-decoration: underline;
  }  
}
.chat_bubble__menu_close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}