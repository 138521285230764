@import '../../../utils/colors.scss';
@font-face {
    font-family: Lato;
    src: url(../../../fonts/Lato-Regular.ttf);
}

.admin_panel__container {
    display: flex;
    justify-content: space-between;
}

.admin_panel__head {
    margin-top: 105px;
}

.admin_panel__links {
    margin-top: 48px;
    width: 670px;
    margin-bottom: 80px;
    svg {
        stroke: #bcbfc2;
    }
     :hover svg {
        stroke: #4e88fb;
    }
}

.admin_panel__link {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey-30;
    text-decoration: none;
    margin-bottom: 32px;
    text-decoration: none;
}

.link {
    &__change_password,
    &__change_password:hover {
        padding-bottom: 36px;
        color: #939699;
        text-decoration: none;
        bottom: 167px;
    }
}

.adminPanelLink:hover {
    text-decoration: none;
    color: $grey-100;
}

.admin_panel__file_button {
    margin-top: 105px;
}