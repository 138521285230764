.training_program {
    margin-top: 59px;
}

.training_program__back_link {
    text-decoration: none;
}

.training_program__header__margin {
    margin-top: 13px;
}

.training_program_blocks {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 30px;
    margin-top: 30px;
}

.training_program_blocks__link {
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.training_program_blocks__link:hover {
    box-shadow: 5px 10px 20px rgb(158, 158, 158);
    border-radius: 14px;
    text-decoration: none;
    position: relative;
}

.training_program_blocks__block {
    background-color: #f1f5f9;
    box-shadow: 0px 30px 30px rgba(1, 1, 1, 0.03);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
}

.training_program_blocks {
    &__title {
        margin: 0;
        max-width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            cursor: pointer;
        }
    }
    &__title2 {
        margin: 0 0 0 -5px;
    }
}

.blocks__titles {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 100%;
}

.addNewFolder {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    height: 48px;
    box-sizing: border-box;
    border-radius: 100px;
    color: #4592ff;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
    &:hover {
        color: #4592ff;
    }
    &:focus {
        box-shadow: none;
    }
}

.plusIcon {
    margin-right: 5px;
}

.foldersPagination {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0 38px 0;
    margin-top: 20px;
}

.menuButton {
    &:hover {
        color: #4592ff;
    }
}

.menuContainer {
    margin-right: 15px;
}

.foldersListNull {
    margin-left: 25px;
    margin-top: 30px;
}

.headTitle {
    margin-top: 40px;
}

.foldersTitle {
    margin-top: 50px;
}

.titleNameFolder {
    margin-top: 15px;
    word-break: break-word;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
}