@font-face {
    font-family: Lato-regular;
    src: url(../../../../../fonts/Lato-Regular.ttf);
}

.wrapper {
    margin-top: 59px;
}

.back_link {
    text-decoration: none;
}

.header {
    margin-top: 13px;
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
}

.tabs {
    margin-top: 39px;
    margin-bottom: 39px;
    width: 600px;
}

.tab_wrapper {
    border-bottom: 1px solid #D9DADB;
}

.tab {
    display: inline-block;
    width: 270px;
    padding: 10px;
    color: #656B70;
    text-decoration: none;
    border-bottom: 2px solid #D9DADB;
    &:hover {
        color: #656B70;
        text-decoration: none;
    }
}

.tab_active,
.tab_active:hover {
    text-decoration: none;
    color: #4592FF;
    border-bottom: 2px solid #4592FF;
}

.modal {
    justify-content: center;
    column-gap: 24px;
}

.button {
    margin-top: 47px;
}

.material_block__text {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-right: 36px;
}

.material_block {
    margin-top: 39px;
}

.modal_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-top: 32px;
}

.button__create_material {
    margin-top: 40px;
    padding: 12px 64px;
    border-radius: 30px;
}

.modal_button {
    border-radius: 30px;
    padding: 12px 18px;
}

.modalTitle {
    font-family: Lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
}