.deleteLesson {
  padding: 12px 18px;
  border: 2px solid #4592ff;
  box-sizing: border-box;
  border-radius: 100px;
  width: 270px;
  margin: 0 22px 0 0;
}

.canselChange {
  box-sizing: border-box;
  background: #4592ff;
  border: 1px solid #4592ff;
  border-radius: 100px;
  padding: 12px 18px;
  width: 270px;
}

.displayButton {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.titleName{
  margin-bottom: 20px;
}

.uploadFile{
  cursor: pointer;
  background: #FFFFFF;
  border: 1px dashed #4592FF;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 14px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #4592FF;
  display: flex;
  justify-content: space-between;
 }

.fileInput {
  opacity: 0;
  display: none;
}

.inputImgWrapper {
  display: flex;
  align-items: center;
}

.inputImgWrapperTxt{
  margin-top: 15px;
  display: flex;
  white-space: break-spaces;
  word-break: break-word;
}

.loading {
  width: 1rem;
  height: 1rem;
  &_wrapper{
    display: flex;
    align-items: center;
  }
}
.loadingText {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4592ff;
  margin-left: 10px;
  margin-bottom: 0;
}

.progressText{
  margin: 10px 0 10px 0;
}