.header {
    margin-top: 57px;
    margin-bottom: 52px;
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
}

.form {
    &_lable_first {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #656B70;
    }
    &_lable_second {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #939699;
    }
    &_input {
        padding: 10px 14px;
        box-sizing: border-box;
        border-radius: 4px;
        max-width: 564px;
        width: 100%;
    }
    &_price {
        &_wrapper {
            position: relative;
            max-width: 270px;
            width: 100%; 
        }
        &_img {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 18px;
            background: white;
        }
        padding: 0 30px 0 14px;
        box-sizing: border-box;
        border-radius: 4px;
        max-width: 564px;
        width: 100%;
    }
    &_group{
        margin-right: 30px;
        width: 270px;
    }
    &_submit {
        border-radius: 100px;
        padding: 12px 18px;
        box-sizing: border-box;
        width: 270px;
        background: #4592FF;
        border: 1px solid #4592FF;
        margin-top: 30px;
    }
    &_radio {
        &_text {
            margin: 0 0 0 12px;
        }
        &_wrapper {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding: 23px 23px 23px 20px;
            border-bottom: 1px solid #ebeef2;
            // box-shadow: 0px -6px 11px rgba(0, 0, 0, 0.04);
        }
    }
    &_bottom{
        &_wrapper{
            display: flex;
        }
    }
}

.tree{
    &_title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #656B70;
    }
    &_wrapper{
        height: 380px;
        overflow-x: auto;
    }
}

.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 13px;
    &_text {
        margin: 0 0 0 14px;
    }
    &:hover {
        text-decoration: none;
    }
}

.cropper{
 &_btn{
    width: 264px;
    height: 48px;
    border: 2px solid #4592FF;
    border-radius: 100px;
    padding: 12px 18px;
    background: transparent;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #4592FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
 }
 &_img{
    margin-right: 10px;
 }
 &_title{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #262D33;
 }
 &_wrapper{
    margin-top: 48px;
    margin-bottom: 40px;
 }
}

.inputImgWrapper {
    display: flex;
    margin: 24px 0 12px 0;
  }
  
  .inputImgWrapperTxt {
    margin: 0;
    align-self: center;
  }
  
  .inputImgWrapperImg {
    margin: 0 10px 0 0;
  }
  
  .inputImgWrapperBtn {
    padding: 0;
    margin: 0 0 0 10px;
  }