$white: #fff;
$blue: #4e88fb;
$green: #82cc86;
$gray-85: #4b5157;
$gray-100: #262d33;
$yellow: #ffd150;
$white-30: rgba(255, 255, 255, 0.3);
$white-70: rgba(255, 255, 255, 0.7);

.chat_bubble__menu_container {
  position: relative;
  display: inline-block;
}
.chat_bubble__menu_list {
  background: white;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  color: $gray-85;
  position: absolute;
  top: -95px;
  right: 0;
  padding: 15px;
}
.chat_bubble__menu_list_item {
  list-style: none;
  padding: 12px 14px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;

  &:hover {
    background-color: rgba(242, 248, 255, 1);
    color: rgba(48, 122, 219, 1);
  }
}
.chat_bubble__menu_close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

.tableEditBtn {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

.tableEditBtnSvg {
  width: 10px;
  height: 20px;
}
