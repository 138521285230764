.header {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0 0;

    .logoutWrapper {
        display: flex;

        .userInfo {
            display: flex;
            align-items: center;
            padding-right: 31px;
            margin-right: 38px;
            border-right: 1px solid #C4C4C4;

            .userRole {
                margin: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #939699;
            }
        }
    }
}

.headerLogo {
    width: 77px;
    height: 48px;
    &_click{
        cursor: pointer;
    }
}

.btn_link {
    text-decoration: none;
}


.link {
    &__change_password,
    &__change_password:hover {
        color: #939699;
        text-decoration: none;
    }
}