$blue-color: #4592ff;
$while-color: #f4f4f4;
$red-color: #dc3545;

%checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    width: 16px;
    height: 16px;
    border: 1px solid #bdcad2;
    border-radius: 4px;
    cursor: pointer;

    &__checked {
        background: $blue-color;
        border-color: $blue-color;
        border-radius: 4px;
        width: 16px;
        height: 16px;
    }
}

.wrapper {
    position: relative;

    &__container {
        position: relative;
        display: flex;
        align-items: center;
        width: 260px;
        height: 48px;
        padding: 0 16px;
        background: $while-color;
        border: 1px solid $while-color;
        border-radius: 12px;
        box-sizing: border-box;

        &__icon {
            cursor: pointer;
        }

        &__width {
            width: 100%;
        }

        &__input {
            outline: none;
            width: 100%;
            height: 100%;
            padding: 17px 8px 17px 0;
            background: $while-color;
            border: none;
            border-radius: 12px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #4b5157;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &-all-selected {
                color: #7b7b7b;
            }

            &-selected {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &:hover {
            border-color: $blue-color;
            .msa-filter-tooltip {
                display: block;
                position: absolute;
                top: -2px;
                max-width: 230px;
                background: #4b5157;
                border-radius: 8px;
                color: white;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                padding: 8px;
                cursor: unset;
                z-index: 2;
                box-shadow: 0px 2px 4px 0px #00000024;
                &::after {
                    content: "";
                    position: absolute;
                    left: -16px;
                    top: 6px;
                    border: 10px solid transparent;
                    border-right: 10px solid #4b5157;
                }
            }
        }
    }

    &__error {
        position: relative;
        display: flex;
        align-items: center;
        width: 260px;
        height: 48px;
        padding: 0 16px;
        background: $while-color;
        border: 1px solid $red-color;
        border-radius: 12px;
        box-sizing: border-box;
    }

    &__message {
        margin-top: 5px;
        font-size: 14px;
        color: $red-color;
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 12px;
        gap: 8px;
        width: 260px;
        max-height: 200px;
        background: #ffffff;
        border: 2px solid #eef1f5;
        border-radius: 12px;
        overflow-y: auto;
        position: absolute;
        margin-top: 4px;
        z-index: 15;

        &__divider {
            width: 100%;
            height: 0px;
            border: 1px solid #eef1f5;
        }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 10px 10px 12px;
            gap: 10px;
            width: 100%;
            height: 36px;
            border-radius: 8px;
            cursor: pointer;

            &__checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-appearance: none;
                appearance: none;
                outline: none;
                width: 16px;
                height: 16px;
                border: 1px solid #bdcad2;
                border-radius: 4px;
                cursor: pointer;

                &__checked {
                    background: $blue-color;
                    border-color: $blue-color;
                    border-radius: 4px;
                    width: 16px;
                    height: 16px;
                }
            }

            &__label {
                height: 14px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #4b5157;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__itemSelect {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 10px 10px 12px;
            gap: 10px;
            width: 100%;
            height: 36px;
            border-radius: 8px;
            cursor: pointer;
            background: #f4f8fc;
        }
    }
}

.wrapper__dropdown::-webkit-scrollbar-track {
    border-radius: 20px;
}

.wrapper__dropdown::-webkit-scrollbar {
    width: 6px;
}

.wrapper__dropdown::-webkit-scrollbar-thumb {
    background-color: $blue-color;
    margin: 0 20px 0 0;
    border-radius: 22px;
}
