.wrapper {
  display: flex;
  flex-direction: column;
  margin: 87px 0 0 0;
}

.back_link {
  text-decoration: none;
  padding: 0;
}

.button_submit {
  margin: 24px 0 0 0;
  height: 48px;
  width: 168px;
  background: #4592ff;
  border: 1px;
  border-radius: 100px;
  font-size: 14px;
}

.head {
  font-weight: 800 bold !important;
  margin-top: 13px;
  margin-bottom: 48px;
}

.divider {
  margin: 24px 0;
  color: #d9dadb;
  background-color: #d9dadb;
  opacity: 1;
}

.input_label {
  font-size: 14px;
  line-height: 24px;
  color: #262d33;
  margin-bottom: 4px;
}

input {
  padding: 14px 10px;
  height: 40px;
}

input::placeholder {
  font-size: 13px;
}

.loading {
  width: 1rem;
  height: 1rem;
}

.passwordInput {
  width: 100% !important;
  padding-right: 50px;
  border-radius: 4px;
}

.input-group > .form-control:focus {
  z-index: 1;
}

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  &:focus {
    z-index: 1;
  }
}

.changeEye {
  position: absolute !important;
  top: 1px;
  right: 0;
  height: 36px;
  transform: translate(-7%, 0%);
  background-color: white;
  display: flex;
  align-items: center;
  &:focus {
    box-shadow: unset;
    outline: 0;
  }
}
