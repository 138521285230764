.breadCrumbs {
    &:hover {
        cursor: pointer;
    }
}

.displayLink {
    display: flex;
    justify-content: space-between;
}

.color {
    color: #0d6efd !important;
    word-break: break-word;
}

.imageBreadCrumb {
    margin-left: 8px;
    margin-right: 8px;
}