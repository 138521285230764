
// @font-face {
//   font-family: Lato-regular;
//   src: url(../../../../fonts/Lato-Regular.ttf);
// }

// @font-face {
//   font-family: Lato-medium;
//   src: url(../../../../fonts/Lato-Medium.ttf);
// }

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(48, 54, 61, 0.8);
    backdrop-filter: blur(16px);
    z-index: 2;
    text-align: center;
    white-space: nowrap;
  }
  
  .modalOverlay::after {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
  }
  
  .modalWindow {
    display: inline-block;
    vertical-align: middle;
    width: 630px;
    min-height: 240px;
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 24px;
    z-index: 2;
    text-align: start;
  }
  
  .popUp {
    width: 630px;
    background-color: white;
    padding: 40px 24px;
  }
  
  .popUpTitle {
    font-family: Lato-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 32px;
    white-space: break-spaces;
  }
  
  .popUpTxt {
    font-family: Lato-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 32px;
  }
  
  .deleteLesson {
    padding: 12px 18px;
    border: 2px solid #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    width: 270px;
  }

  .deleteError{
    padding: 12px 18px;
    border: 2px solid #4592ff;
    background: #4592ff;
    box-sizing: border-box;
    border-radius: 100px;
    width: 140px;
}

.error{
  &_title{
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;    
    color: #000000;
    margin-bottom: 24px;
  }
  &_text{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #272833;
    margin-bottom: 32px;
  }
}
  
  .canselChange {
    box-sizing: border-box;
    background: #4592ff;
    border: 1px solid #4592ff;
    border-radius: 100px;
    padding: 12px 18px;
    width: 270px;
  }
  
  .loading {
    width: 1rem;
    height: 1rem;
  }
  
  .displayButton {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .displayHint {
    margin-top: 10px;
  }
  
  
  .wrapper {
      margin-top: 59px;
    }
    
    .back_link {
      text-decoration: none;
    }
    
    .header {
      margin-top: 13px;
      margin-bottom: 48px;
      display: flex;
      justify-content: space-between;
    }
    
    .tabs {
      margin-top: 30px;
      margin-bottom: 39px;
      padding: 0;
    }
    
    .tab_wrapper {
      padding: 0;
      display: flex;
      justify-content: space-around;
    }
    
    .tab {
      display: inline-block;
      width: 270px;
      padding: 10px;
      color: #656b70;
      text-decoration: none;
      border-bottom: 2px solid #d9dadb;
      &:hover {
        color: #656b70;
        text-decoration: none;
      }
    }
    
    .emptytab {
      height: 46px;
      width: 100%;
      display: inline-block;
      padding: 10px;
      color: #656b70;
      text-decoration: none;
      border-bottom: 2px solid #d9dadb;
    }
    
    .tab_active,
    .tab_active:hover {
      text-decoration: none;
      color: #4592ff;
      border-bottom: 2px solid #4592ff;
    }
    
    .modal {
      justify-content: center;
      column-gap: 24px;
    }
    
    .button {
      margin-top: 47px;
    }
    
    .material_block__text {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      margin-right: 36px;
    }
    
    .material_block {
      margin-top: 39px;
    }
    
    .modal_buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      margin-top: 32px;
    }
    
    .button__create_material {
      margin-top: 40px;
      padding: 12px 64px;
    }
    
    .findUsers {
      width: 600px;
    }
    