.lessonsPrevPage {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #4e88fb;
    text-decoration: none;
    height: 32px;
}

.lessonNextPage {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #30363d;
    text-decoration: none;
    width: 39px;
    height: 32px;
    border-radius: 4px;
}

.lessonNextPage:active,
.lessonNextPage:focus,
.lessonNextPage:hover {
    outline: none;
}

.lessonNextPage::-moz-focus-inner {
    border: 0;
}

.btn_link {
    text-decoration: none;
    color: #30363d;
    padding: 0;
}

.arrowNext {
    transform: rotate(180deg);
    margin-left: 10px;
}

.arrowPrev {
    margin-right: 10px;
}

.wrapper {
    margin-bottom: 36px;
}