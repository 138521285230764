$blue-color: #4592ff;
$hover-color: #4b5157;

.button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 222px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    padding: 16px 14px 16px 20px;
    border: 2px solid $blue-color;
    border-radius: 16px;
    height: 48px;
    color: $blue-color;
    background-color: white;

    svg {
        fill: $blue-color;
    }

    &:hover {
        border: 2px solid $hover-color;
        color: $hover-color;
        svg {
            fill: $hover-color;
        }
    }
}
