.btn_link {
    width: 65px;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
    padding: 0;
    display: flex;
    margin-top: 58px;
    margin-bottom: 13px;

    &_text {
        margin: 0 0 0 14px;
    }

    &:hover {
        text-decoration: none;
    }
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: #4B5157;
    margin: 0 0 52px 0;

    &_debounce {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #262D33;
        margin: 0 0 8px 0;
    }
}

.findUsers {
    max-width: 530px;
    width: 100%;
    border: 1px solid #939699;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 16px 10px 40px;

    &:hover {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }

    &:focus-within {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
        transition: all 0.5s ease-in-out;
    }
}

.searhWrapper {
    position: relative;

    .searchImg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0, -50%);
    }
}

.table {
    &_wrapper {
        margin-bottom: 36px;
    }

    &_width {
        width: 100%;
        margin-top: 36px;
        margin-bottom: 36px;
    }

    &_header {
        border-bottom: 1px solid #ebeef2;
        height: 64px;
    }

    &_body {
        border-bottom: 1px solid #ebeef2;
        height: 64px;
    }

    &_number {
        padding: 23px 12px;
        width: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;

        &_header {
            padding: 23px 12px;
            width: 100px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }

    &_user {
        width: 500px;
        padding: 23px 12px 23px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;

        svg {
            stroke: #4B5157;
        }

        &:hover {
            cursor: pointer;

            svg {
                stroke: #4285f4;
            }
        }

        &_arrow {
            margin-left: 8px;
        }

        &_header {
            width: 500px;
            padding: 23px 12px 23px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }

    &_pruchase {
        width: 400px;
        padding: 23px 12px 23px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;

        &_header {
            width: 400px;
            padding: 23px 12px 23px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }

    &_count {
        text-align: center;
        width: 400px;

        &_header {
            text-align: center;
            width: 400px;
            padding: 23px 12px 23px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;
        }
    }

    &_date {
        width: 170px;
        padding: 23px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272833;

        &_header {
            width: 170px;
            padding: 23px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #656B70;

            &_btn {
                border: unset;
                background: transparent;
                margin-left: 8px;
            }
        }
    }
}

.nothing_find {
    color: #939699;
    font-family: Lato-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: start;
}