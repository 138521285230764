.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.preloader {
  width: 20%;
  height: 20%;
  animation: preloader-rotate 2s infinite linear;
}

.preloaderSmall {
  width: 10%;
  height: 10%;
  animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 2rem;
  height: 2rem;
}

.loadingText {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4592ff;
}
